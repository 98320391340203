import React from "react"
import { emptyArray } from "../../../../common/misc-utils"
import { sortByOrder } from "../../../../common/sorters"
import { withoutDomain } from "../../../../common/url-utils"
import { TPoolRouteProps } from "../../../../routes.d"
import { useLocalKey } from "../../../Base/LocalKey"
import Link from "../../../components/Link"
import useControls from "../../../hooks/useControls"
import { extractPoolSettings } from "../../../hooks/usePoolData"
import NexusSelect, { INexusField } from "../../../shared/molecules/Form/Select"
import { toNodes } from "../../../utils/misc-utils"
import { getParam, setParam } from "../../../utils/url-utils"
import { PoolHeaderControlsSection, SelectContainer, ToggleContainer, ToggleContainerButton } from "../styles/PoolMyEntry.styles"

const subnavLinks = [
  {
    key: "standings",
    title: "Overall",
  },
  {
    key: "all-picks",
    title: "Live",
  },
]
const tabQueryParam = "standings"
const defaultTab = "All Players"

function StandingsLinksV2(props: TPoolRouteProps) {
  const {
    location: { pathname, search },
    history,
  } = props
  const { poolData } = props
  const controls = useControls(props)
  const { detailedPeriod, detailedEntry, isInComingSoon, gameInstanceForArea, allSegments } = poolData
  const order = detailedPeriod && detailedPeriod.order
  const { updateOrder } = controls
  const setFieldValue = (fieldName: string, value: any) => {
    // console.log(`setFieldValue: ${value}`)
    if (fieldName === "order") {
      updateOrder(parseInt(value))
    } else if (fieldName === "segment") {
      const segment = allSegments.find((seg) => seg.id === value)
      if (segment) {
        const url = pathname + "?" + setParam(setParam(search, "seasonType", segment.season.season), "year", segment.season.year)
        history.push(url)
      }
    } else {
      history.push(value)
    }
  }

  const onSelectChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    // console.log(event)
    const { name, value } = event.target
    setFieldValue(name, value)
    // window.EE = event.target
  }
  const fakeForm = {
    setFieldValue,
  }
  const queryValue = getParam(tabQueryParam, search)
  const tabFromPath = subnavLinks.find(({ key }) => pathname.includes(key))
  const matchedTab = tabFromPath || subnavLinks.find(({ key }) => queryValue === key) || subnavLinks[0]
  const isOnOverallStandingsTab = matchedTab && matchedTab.key === subnavLinks[0].key
  const edges = detailedEntry?.subPoolEntries
  const subPoolEntries = edges ? toNodes(edges) : ([] as never[])
  const toggleProps = useLocalKey("StandingsLinksToggle")
  const poolEntry = subPoolEntries.find((spe) => pathname.includes(spe.subPool.id) || search.includes(spe.subPool.id))
  if (!subPoolEntries.length && isInComingSoon) {
    return null // <PoolHeaderControlsSection />
  }
  const currentQuery = search // ? `?${search}` : ''
  const defaultTo = withoutDomain((detailedEntry && detailedEntry.pool && `${detailedEntry.pool.url}/${matchedTab.key}`) || pathname) + currentQuery
  const options = subPoolEntries.map((spe) => ({
    label: spe.subPool.name,
    value: withoutDomain(spe.subPool.url) + currentQuery,
  }))
  options.unshift({
    label: defaultTab,
    value: defaultTo,
  })
  const field = {
    name: "filterUrl",
    onChange: onSelectChange,
    value: poolEntry ? pathname + currentQuery : defaultTo,
  } as INexusField
  const poolSettings = extractPoolSettings(poolData)
  const periodEdges = detailedPeriod?.segment.periods
  const periodOptions =
    (periodEdges &&
      poolSettings &&
      toNodes(periodEdges)
        .sort(sortByOrder)
        .filter(
          (per) =>
            per.id === detailedPeriod?.id ||
            !per.tournamentId ||
            !poolSettings.tournamentIds ||
            poolSettings.tournamentIds.includes(per.tournamentId),
        )
        .map((per) => {
          return {
            label: per.description,
            value: per.order,
          }
        })) ||
    emptyArray
  const currentSegmentId = detailedPeriod && detailedPeriod.segment.id
  const seasonOptions =
    (gameInstanceForArea &&
      allSegments
        .filter((seg) => seg.season.gameInstance.id === gameInstanceForArea.id)
        .map((seg) => {
          const url = pathname + "?" + setParam(setParam(search, "seasonType", seg.season.season), "year", seg.season.year)
          return {
            label: `${seg.season.year} ${seg.season.season}`,
            value: url,
          }
        })) ||
    emptyArray
  const orderField = {
    name: "order",
    onChange: onSelectChange,
    value: order,
  } as INexusField
  const segmentField = {
    name: "segment",
    onChange: onSelectChange,
    value: currentSegmentId,
  } as INexusField
  const hasPeriodNav = !isOnOverallStandingsTab && periodOptions.length > 1
  const hasSeasonNav = seasonOptions.length > 1 && false
  const hasTimeOptionsSection = hasPeriodNav || hasSeasonNav
  const sections = [] as Array<React.ReactElement<any>>
  if (subPoolEntries.length) {
    sections.push(
      <SelectContainer className={`section section--${sections.length}`}>
        <NexusSelect field={field} form={fakeForm} options={options} defaultValue={defaultTo} placeholder={defaultTab} />
        {/* 
        // WTF...
        {(hasTimeOptionsSection && (
          <OpacityBtn as="button" type="button" className="toggler" onClick={() => toggleProps.update(!toggleProps.value)}>
            <span>History</span>
            <CarrotSvg />
          </OpacityBtn>
        )) ||
          null} */}
      </SelectContainer>,
    )
  }
  if (!isInComingSoon) {
    sections.push(
      <ToggleContainer className={`section section--${sections.length}`}>
        {subnavLinks.map((tab) => (
          <ToggleContainerButton
            as={Link}
            isActive={matchedTab === tab}
            key={tab.key}
            to={!tabFromPath ? { search: setParam(search, tabQueryParam, tab.key) } : pathname.replace((matchedTab && matchedTab.key) || "", tab.key)}
          >
            {tab.title}
          </ToggleContainerButton>
        ))}
      </ToggleContainer>,
    )
  }
  if (detailedPeriod && hasTimeOptionsSection) {
    sections.push(
      <SelectContainer className={`section section--${sections.length}`}>
        {(hasPeriodNav && (
          <NexusSelect
            field={orderField}
            form={fakeForm}
            options={periodOptions ? periodOptions : []}
            defaultValue={order ? order.toString() : undefined}
            placeholder={detailedPeriod.description}
          />
        )) ||
          null}
        {(hasSeasonNav && <NexusSelect field={segmentField} form={fakeForm} options={seasonOptions ? seasonOptions : []} />) || null}
      </SelectContainer>,
    )
  }
  // console.groupCollapsed('StandingsLinks')
  // console.dir(detailedPeriod)
  // console.dir(gameInstance)
  // console.dir(allSegments)
  // console.dir(periodOptions)
  // console.groupEnd()
  return sections.length > 0 ? (
    <PoolHeaderControlsSection className={`items--${sections.length} is-toggled--${!!toggleProps.value}`}>
      {sections.map((section, i) => (
        <React.Fragment key={i}>{section}</React.Fragment>
      ))}
    </PoolHeaderControlsSection>
  ) : null
}

const StandingsLinks = React.memo(StandingsLinksV2)

export default StandingsLinks
