import styled from "styled-components/macro"
import { AvatarBase } from "../../../components/Avatar"
import { OpacityBtn } from "../../../components/Button"
import { FlexCol, FlexColGrow, FlexRow } from "../../../components/FlexComponents"
import { breakpoints, fontWeight, layout, palette, pxToRem, zIndexes } from "../../../utils/style-utils"

export const poolPageInnerGutterW = 8
export const PoolPageInner = styled.div`
  min-height: 60vh;
  background-color: #fff;
  border-radius: ${pxToRem(8)};
  margin: 0 ${pxToRem(poolPageInnerGutterW)} ${pxToRem(32)};
`

export const StickyTopBar = styled.div`
  position: sticky;
  top: ${pxToRem(0)};
  left: 0;
  width: 100%;
  background-color: ${palette.gray95};
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(2)} ${pxToRem(-2)} rgba(0, 0, 0, 0.25);
  & > * {
    z-index: ${zIndexes.ui0};
  }
`
export const StickyTopBarBoxShadowProtector = styled.div`
  height: ${pxToRem(2)};
  position: relative;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: ${pxToRem(2)};
    width: 100%;
    background-color: ${palette.gray95};
    z-index: ${-1};
  }
`

export const PoolHeaderControlsSection = styled.div`
  min-height: ${pxToRem(16)};
  display: flex;
  flex-flow: row wrap;
  padding: ${pxToRem(layout.pageGutterW)} ${pxToRem(layout.pageGutterW)};
  padding: var(--grid-gap) var(--grid-margin);
  & .Dropdown-list {
    width: 100%;
  }
  & .Dropdown {
    min-width: ${pxToRem(150)};
  }
  & .section {
    display: flex;
    justify-content: space-between;
  }
  & .toggler {
    font-size: 0.8em;
    flex-shrink: 0;
  }
  & .toggler span {
    font-size: 0.8em;
    white-space: nowrap;
    padding-left: ${pxToRem(6)};
  }
  & .toggler svg {
    min-width: 1em;
    min-height: 1em;
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    transform: rotate(90deg);
    margin-left: 0.5em;
    transition: transform 0.2s ease-out;
  }
  &.is-toggled--true {
    & .toggler svg {
      transform: rotate(-90deg);
    }
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    &.is-toggled--false .section:nth-child(3) {
      max-height: 0 !important;
      overflow: hidden !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    &.items--3 .section {
      min-width: 100%;
      padding-bottom: ${pxToRem(8)};
      margin-bottom: 0 !important;
      flex: 1;
      overflow: visible;
    }
    &.items--3 .section > *:first-child {
      width: 100%;
    }
    &.items--3 .section > .Dropdown {
      flex: 1;
    }
    &.items--3 .Dropdown {
      flex: 1;
      display: flex;
    }
    &.items--3 .Dropdown > * {
      width: 100%;
    }
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    &.items--3 .toggler {
      display: none;
    }
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    & > *:not(:first-child) {
      padding-left: ${pxToRem(8)};
    }
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & > *:nth-child(2n) {
      flex: 1 1 auto;
      display: inline-flex;
      & > * {
        flex: 0 1 ${pxToRem(400)};
      }
    }
    &.is-dirty--true > *:nth-child(2n) {
      flex: 1 0 100%;
    }
    &.is-dirty--true .hide-mobile-if-dirty {
      display: none;
    }
    &.is-dirty--true > * {
      justify-content: space-around;
      margin-top: ${pxToRem(8)};
    }
    & > *:first-child {
      flex: 1 1 100%;
      margin-bottom: ${pxToRem(8)};
    }
    &.is-dirty--false > *:last-child {
      flex: 0 0 ${pxToRem(60)};
    }
    &.is-dirty--false .hide-mobile-if-clean {
      display: none;
    }
  }
`

export const SelectContainer = styled.div`
  & > *:not(:first-child) {
    margin-left: ${pxToRem(6)};
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      flex: 1;
    }
  }
  @media (max-width: ${pxToRem(layout.nexusTogglesWrapW)}) {
    & {
      width: 100%;
      min-width: 100%;
      flex: 1 0 100%;
      margin-bottom: ${pxToRem(8)};
    }
    & .Dropdown {
      display: block;
    }
  }
`

export const ToggleContainer = styled.div`
  display: inline-flex;
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      flex: 1;
    }
  }
`
export const RightControlsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  height: ${pxToRem(32)};
  & > * {
    margin-left: ${pxToRem(8)};
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & > * {
      margin-left: 0;
    }
  }
`

export const NoSpreadFlag = styled.div`
  padding: 0 ${pxToRem(3)};
  font-size: ${pxToRem(9)};
  color: ${palette.white};
  font-weight: ${fontWeight.semiBold};
  background-color: ${palette.orange};
  border: ${pxToRem(1)} solid ${palette.orange};
  border-radius: ${pxToRem(2)};
  text-transform: uppercase;
  line-height: 1.3em;
`

export const ToggleContainerButton = styled.div<{ isActive: boolean }>`
  padding: 0 ${pxToRem(42)};
  height: ${pxToRem(32)};
  font-size: ${pxToRem(12)};
  font-weight: ${fontWeight.semiBold};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 ${pxToRem(132)};
  border: ${pxToRem(1)} solid ${({ isActive }) => (isActive ? "#2287e6" : "#dfe2e6")};
  color: ${({ isActive }) => (isActive ? "#13161a" : "#959ea6")};
  background-color: #fff;
  white-space: nowrap;
  border-right-width: ${({ isActive }) => pxToRem(isActive ? 1 : 0)};
  transition: all 0.2s ease-out;
  @media (hover: hover) {
    &:hover {
      background-color: #f5faff;
      color: #13161a;
    }
  }
  &:active {
    background-color: #f5faff;
    color: #13161a;
  }
  &:first-child {
    border-radius: ${pxToRem(2)} 0 0 ${pxToRem(2)};
  }
  &:last-child {
    border-right-width: ${pxToRem(1)};
    border-radius: 0 ${pxToRem(2)} ${pxToRem(2)} 0;
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      padding: 0 ${pxToRem(8)};
    }
  }
`

export const MyEntryForm = styled(FlexRow)`
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  & > * {
    background: #fff;
    min-width: 200px;
    flex: 1 1 auto;
    margin-bottom: ${pxToRem(layout.pageGutterW)};
    margin-bottom: var(--grid-gap);
    border: ${pxToRem(1)} solid ${palette.gray3};
  }
  @media (min-width: ${breakpoints.handheld}px) {
    & > *:not(:first-child) {
      margin-left: ${pxToRem(layout.pageGutterW)};
      margin-left: var(--grid-gap);
    }
  }
  @media (max-width: ${pxToRem(800)}) {
    justify-content: center;
  }
`
export const formGuttersW = layout.pageGutterW
export const MyEntryAvatarFieldset = styled.fieldset`
  padding: ${pxToRem(layout.pageGutterW)};
  min-width: ${pxToRem(200)};
  font-weight: ${fontWeight.semiBold};
  position: relative;
  & button {
    position: relative;
  }
  & button:not([disabled]) * {
    pointer-events: all;
    cursor: pointer;
  }
  & input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: block;
    opacity: 0;
  }
  & > * {
    margin: auto;
  }
`
export const MyEntryInputContainer = styled(FlexRow)`
  min-height: ${pxToRem(40)};
`
export const ListMaxWidthContainer = styled.div`
  padding-top: ${pxToRem(8)};
  margin: 0 auto;
  & > * {
    padding: 0 ${pxToRem(8)} ${pxToRem(8)};
  }
`
export const SetEventsItem = styled.div`
  display: flex;
  flex-flow: row wrap;
  background-color: ${palette.white};
  border: ${pxToRem(1)} solid ${palette.gray6};
  padding-right: ${pxToRem(8)};
  transition: all 0.2s ease-out;
  box-shadow: 0 1px 1px 0 rgba(177, 177, 177, 0.1);
  & svg path {
    transition: all 0.2s ease-out;
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${palette.blue4};
      border-color: ${palette.gray3};
    }
  }
  &:active {
    background-color: ${palette.blue4};
    border-color: ${palette.gray3};
  }
  &.is-selected--true {
    border-color: ${palette.blue2};
  }
  & > * {
    height: ${pxToRem(56)};
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & > * {
      height: ${pxToRem(51)};
    }
  }
  @media (max-width: ${pxToRem(layout.pageContentMaxW)}) {
    & {
      border-left: 0;
      border-right: 0;
    }
  }
`
export const SetEventsItemsContainer = styled.div`
  width: 100%;
  & > * {
    margin: 0 0 ${pxToRem(8)};
    width: 100%;
  }
`
export const SetEventsItemInfo = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: ${palette.gray1};
  font-size: ${pxToRem(12)};
  white-space: nowrap;
  & strong {
    font-weight: ${fontWeight.bold};
  }
  & > span {
    width: ${pxToRem(55)};
    display: inline-block;
  }
`
export const SetEventsItemGrow = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row;
  align-items: center;
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      flex-flow: column-reverse;
      align-items: flex-start;
      padding-top: ${pxToRem(8)};
    }
  }
`
export const SetEventsItemTeam = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  & > * {
    margin-right: 0.5em;
  }
  &:last-child {
    justify-content: flex-start;
  }
  @media (min-width: ${pxToRem(700)}) {
    & small {
      display: none;
    }
  }
  @media (max-width: ${pxToRem(700)}) {
    & span {
      display: none;
    }
  }
`
export const SetEventsItemName = styled.div`
  flex: 1 1 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${fontWeight.semiBold};
  font-size: ${pxToRem(14)};
  color: ${palette.default};

  & > * {
    margin-right: ${pxToRem(8)};
    display: block;
  }
  & i {
    font-size: ${pxToRem(12)};
  }
  & b {
    font-size: ${pxToRem(12)};
    font-weight: ${fontWeight.regular};
  }
  & .team-img {
    width: ${pxToRem(19)};
    height: ${pxToRem(21)};
    display: block;
  }
  @media (min-width: ${pxToRem(700)}) {
    & small {
      display: none;
    }
  }
  @media (max-width: ${pxToRem(700)}) {
    & span {
      display: none;
    }
  }
`
export const SetEventsApprovedCheckbox = styled.label`
  width: ${pxToRem(56)};
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: all 0.2s ease-out;
  &label {
    cursor: pointer;
  }
  & input {
    z-index: ${zIndexes.ui1};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  & svg {
    height: ${pxToRem(16)};
    width: ${pxToRem(16)};
    pointer-events: none;
  }
  & span {
    font-size: ${pxToRem(11)};
    padding-top: ${pxToRem(4)};
    font-weight: ${fontWeight.semiBold};
  }
  & div {
    font-size: ${pxToRem(11)};
  }

  &.buttonish {
    display: inline-flex;
    flex-flow: row nowrap;
    width: auto;
    border-radius: ${pxToRem(2)};
    padding: 0 ${pxToRem(12)};
    background-color: ${palette.blue2};
    @media (hover: hover) {
      &:hover {
        background-color: ${palette.unknownButtonActiveBlue};
      }
    }
    &:active {
      background-color: ${palette.unknownButtonActiveBlue};
    }

    &.is-valid--false {
      background-color: ${palette.gray3};
    }

    & svg {
      margin-right: ${pxToRem(6)};
    }
    & div {
      font-size: ${pxToRem(13)};
      font-weight: ${fontWeight.semiBold};
      color: #fff;
    }
  }
`
export const SetEventsItemInput = styled.div`
  & input {
    color: #5b6c77;
    font-weight: ${fontWeight.semiBold};
    border: ${pxToRem(1)} solid #e6e7e8;
    width: 100%;
    height: 100%;
    padding: ${pxToRem(6)} ${pxToRem(8)};
    text-align: center;
    border-radius: ${pxToRem(3)};
    width: ${pxToRem(50)};
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      margin-bottom: ${pxToRem(12)};
      margin-top: -${pxToRem(12)};
      margin-left: auto;
    }
  }
`
export const SetEventsItemCheckmark = styled.div`
  width: 3em;
  padding: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }
`

export const MyEntryLeftCol = styled(FlexCol)`
  min-width: ${pxToRem(50)};
  color: #878787;
  font-weight: ${fontWeight.semiBold};
  display: block;
  & input {
    margin: ${pxToRem(8)} 0 ${pxToRem(8)} ${pxToRem(25)};
  }
`
export const MyEntryRightCol = styled(FlexColGrow)`
  white-space: normal;
  & span {
    padding: ${pxToRem(8)};
    color: #878787;
  }
`
export const MyEntryField = styled.input`
  color: ${palette.black};
  font-weight: ${fontWeight.semiBold};
  margin: 0 ${pxToRem(8)};
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 150px;
  background: transparent;
  border-bottom: ${pxToRem(1)} solid ${palette.uiBlue1};
  transition: all 0.2s ease-out;
  &:disabled {
    border-color: rgba(0, 0, 0, 0);
  }
`
export const MyEntryAvatarContainer = styled(FlexRow)`
  margin: ${pxToRem(16)} auto;
  max-width: ${pxToRem(140)};
  width: ${pxToRem(140)};
  height: ${pxToRem(140)};
  & svg {
    width: ${pxToRem(60)};
    height: ${pxToRem(60)};
  }
`
export const MyEntryAvatar = styled(AvatarBase)`
  background-color: #fff;
  border: ${pxToRem(1)} solid #ccc;
  padding: ${pxToRem(5)};
`
export const MyEntryEditBtn = styled(OpacityBtn)`
  padding: ${pxToRem(8)};
  color: ${palette.uiBlue1};
  &:disabled {
    transition: none;
    opacity: 0;
  }
  & svg {
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
  }
`

export const MyEntryLegend = styled.legend`
  font-size: ${pxToRem(15)};
  padding: 0 0 ${pxToRem(layout.pageGutterW)};
  border-bottom: ${pxToRem(1)} solid ${palette.gray3};
`
